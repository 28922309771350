import { createSlice } from "@reduxjs/toolkit"

const initialOrdersState = {
  listLoading: false,
  actionsLoading: false,
  orderEditComment: "",
  totalCount: 0,
  totalCountDueTomorrow: 0,
  totalCountDueDayAfter: 0,
  totalCountOverDue: 0,
  entities: null,
  entitiesDueTomorrow: null,
  entitiesDueDayAfter: null,
  entitiesOverDue: null,
  orderForEdit: undefined,
  lastError: null,
  attributes: {},
  commets: [],
  orderCustomers: [],
  orderItemProducts: [],
  orderProductAttributes: [],
  productionOrderCustomers: []
}
export const callTypes = {
  list: "list",
  action: "action",
}

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    clearOrderEdit: (state) => {
      state.orderForEdit = null
    },
    // clearOrder: (state) => {
    //   state.orderForEdit = null
    // },
    // getOrderById
    orderFetched: (state, action) => {
      state.actionsLoading = false
      state.listLoading = false
      state.orderForEdit = action.payload.orderForEdit
      state.error = null
    },
    // getOrderById
    orderCustomersFetched: (state, action) => {
      state.actionsLoading = false
      state.listLoading = false
      state.orderCustomers = action.payload.orderCustomers
      state.error = null
    },
    productionReportCustomersFetched: (state, action) => {
      state.actionsLoading = false
      state.listLoading = false
      state.productionOrderCustomers = action.payload.orderCustomers
      state.error = null
    },
    // findOrders
    ordersFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // Due Tomorrow
    ordersDueTomorrowFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.entitiesDueTomorrow = entities
      state.totalCountDueTomorrow = totalCount
    },
    // Due Tomorrow
    ordersDueDayAfterFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.entitiesDueDayAfter = entities
      state.totalCountDueDayAfter = totalCount
    },
    ordersOverDueFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.entitiesOverDue = entities
      state.totalCountOverDue = totalCount
    },
    orderAttributesFetched: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.attributes = action.payload.attributes
    },
    orderItemProductsFetched: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.orderItemProducts = action.payload.orderItemProducts
    },
    orderItemAttributesFetched: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null

      const { pid, orderItemAttributes: attrs } = action.payload
      // const prods = [...];
      const prod = state.orderItemProducts.find((p) => p.pid === pid)
      prod.attributes = {}
      attrs.forEach((attr) => {
        if (attr.name) {
          prod.attributes[attr.name] = null
        }
      })
      const others = state.orderItemProducts.filter((p) => p.pid !== pid)
      state.orderItemProducts = [...others, prod]
    },
    orderItemAttributeValuesFetched: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null

      const { pid, attr_name, orderItemAttributeValues } = action.payload
      // const prods = [...state.orderItemProducts];
      const prod = state.orderItemProducts.find((p) => p.pid === pid)

      prod.attributes[attr_name] = {
        values: orderItemAttributeValues,
      }

      const others = state.orderItemProducts.filter((p) => p.pid !== pid)
      state.orderItemProducts = [...others, prod]
    },
    // createOrder
    orderCreated: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.orderForEdit = null
    },
    // updateOrder
    orderUpdated: (state, action) => {
      state.error = null
      state.listLoading = false
      state.actionsLoading = false
      state.orderForEdit = null
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.order.id) {
      //     return action.payload.order
      //   }
      //   return entity
      // })
    },
    orderCommentFetched: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.listLoading = false
      state.orderEditComment = action.payload.comment
    },
    orderCommentsFetched: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.listLoading = false
      state.comments = action.payload.comments
    },
    orderCommentUpdated: (state, action) => {
      state.error = null
      state.listLoading = false
      state.actionsLoading = false
      state.orderEditComment = action.payload.comment
    },
    // deleteOrder
    orderDeleted: (state, action) => {
      state.error = null
      state.listLoading = false
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => el.id !== action.payload.id)
    },
    // deleteOrders
    ordersDeleted: (state, action) => {
      state.error = null
      state.listLoading = false
      state.actionsLoading = false
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id))
    },
    // ordersUpdateState
    ordersStatusUpdated: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      const { ids, status } = action.payload
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status
        }
        return entity
      })
    },

    orderProductsAttributesFetched: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null

      let { products, attributes } = action.payload
      let attrs = {}

      //create attrs
      for (let i = 0; i < attributes.length; i++) {
        if (attrs[attributes[i].pid] === undefined) {
          attrs[attributes[i].pid] = [attributes[i].name]
          continue
        } else {
          let selected = attrs[attributes[i].pid].find((a) => a === attributes[i].name)
          if (!selected) {
            attrs[attributes[i].pid] = [...attrs[attributes[i].pid], attributes[i].name]
          }
        }
      }

      //step1 - create orderItemProducts
      Object.keys(attrs).forEach((a) => {
        /*eslint eqeqeq: "off"*/
        let prod = products.find((p) => p.pid == a)
        if (prod.attributes === undefined) prod.attributes = {}
        attrs[prod.pid].forEach((attr_name) => {
          prod.attributes[attr_name] = null
        })
      })

      //step2 - update orderItemProducts
      attributes.forEach((attr) => {
        let prod = products.find((p) => p.pid === attr.pid)
        Object.keys(prod.attributes).forEach((eachAttr) => {
          if (eachAttr === attr.name) {
            if (prod.attributes[attr.name] === null) prod.attributes[attr.name] = []
            prod.attributes[attr.name] = [...prod.attributes[attr.name], attr]
          }
        })
      })

      //set orderItemProducts
      state.orderItemProducts = products
    },
  },
})
