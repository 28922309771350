import { createSlice } from "@reduxjs/toolkit"

const initialInventoryHistoryDetailedState = {
  inventoryHistoryListLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null
}
export const callTypes = {
  list: "list",
  action: "action",
}

export const inventoryHistorySlice = createSlice({
  name: "inventoryHistoryDetailed",
  initialState: initialInventoryHistoryDetailedState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.inventoryHistoryListLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.inventoryHistoryListLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    inventoryDetailedFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.inventoryHistoryListLoading = false
      state.actionsLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
  },
})
