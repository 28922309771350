import * as requestFromServer from "./ordersCrud";
import { ordersSlice, callTypes } from "./ordersSlice";

const { actions } = ordersSlice;

export const clearOrderEdit = () => (dispatch) => {
  dispatch(actions.clearOrderEdit());
};

export const fetchOrders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllOrders(queryParams)
    .then((response) => {
      const totalCount = response.data.length;
      const entities = response.data;

      dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrderItemProducts = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getOrderItemProducts()
    .then((response) => {
      // console.log("repsnos", response.data);
      const orderItemProducts = response.data;
      dispatch(actions.orderItemProductsFetched({ orderItemProducts }));
      dispatch(fetchOrderProductsAttributes());
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't find order item products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrderItemAttributes = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getOrderItemAttributes(queryParams)
    .then((response) => {
      const orderItemAttributes = response.data;
      dispatch(
        actions.orderItemAttributesFetched({
          pid: queryParams.pid,
          orderItemAttributes,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find order item attributes";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrderItemAttributeValues = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getOrderItemAttributeValues(queryParams)
    .then((response) => {
      const orderItemAttributeValues = response.data;
      dispatch(
        actions.orderItemAttributeValuesFetched({
          pid: queryParams.pid,
          attr_name: queryParams.attr_name,
          orderItemAttributeValues,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find order item attribute values";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findOrders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrders(queryParams)
    .then((response) => {
      const totalCount = response.data.length;
      const entities = response.data;

      dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const findAllOrders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAllOrders(queryParams)
    .then((response) => {
      const totalCount = response.data.length;
      const entities = response.data;

      dispatch(actions.ordersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findAllOrdersDueTomorrow = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAllOrdersDueTomorrow(queryParams)
    .then((response) => {
      const totalCount = response.data.length;
      const entities = response.data;
      dispatch(actions.ordersDueTomorrowFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findAllOrdersDueDayAfter = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAllOrdersDueDayAfter(queryParams)
    .then((response) => {
      const totalCount = response.data.length;
      const entities = response.data;

      dispatch(actions.ordersDueDayAfterFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findAllOrdersOverDue = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAllOrdersOverDue(queryParams)
    .then((response) => {
      const totalCount = response.data.length;
      const entities = response.data;

      dispatch(actions.ordersOverDueFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrder = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.orderFetched({ orderForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderById(id)
    .then((response) => {
      const order = response.data;
      console.log({ order });
      dispatch(actions.orderFetched({ orderForEdit: order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOrderCustomers = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderCustomers()
    .then((response) => {
      const customers = response.data;
      dispatch(actions.orderCustomersFetched({ orderCustomers: customers }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find order customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOrderCustomersProductPage = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductReportCustomers()
    .then((response) => {
      const customers = response.data;
      dispatch(
        actions.productionReportCustomersFetched({ orderCustomers: customers })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find production report customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOrderAttributes = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderAttributes(id)
    .then((response) => {
      const attributes = response.data;
      dispatch(actions.orderAttributesFetched({ attributes }));
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't find order customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// export const deleteOrder = id => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteOrder(id)
//     .then(response => {
//       dispatch(actions.orderDeleted({ id }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete order";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const deleteOrder = function(id) {
  return async function(dispatch) {
    dispatch(actions.startCall({ callType: callTypes.action }));

    return await requestFromServer
      .deleteOrder(id)
      .then((response) => {
        // dispatch(actions.orderDeleted({ id }));
      })
      .catch((error) => {
        error.clientMessage = "Can't delete order";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };
};

export const createOrder = (orderForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrder(orderForCreation)
    .then((response) => {
      const order = response.data;
      dispatch(actions.orderCreated({ order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateOrder = (order) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrder(order)
    .then((response) => {
      const order = response.data;
      dispatch(actions.orderUpdated({ order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateOrderStatus = (order) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrderStatus(order)
    .then((response) => {
      const order = response.data;
      dispatch(actions.orderUpdated({ order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// /order-comments
export const fetchOrderComments = (orderId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchOrderComments(orderId)
    .then((response) => {
      dispatch(actions.orderCommentsFetched({ comments: response.data }));
    })
    .catch((error) => {
      console.log("error");

      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
// /order
export const fetchOrderComment = (orderId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchOrderComment(orderId)
    .then((response) => {
      dispatch(actions.orderCommentFetched({ comment: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateOrderComment = ({ id, orderComment }) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrderComment({ id, orderComment })
    .then((response) => {
      dispatch(actions.orderCommentUpdated({ comment: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update order comment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrdersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOrders(ids, status)
    .then(() => {
      dispatch(actions.ordersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update orders status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrders = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrders(ids)
    .then(() => {
      dispatch(actions.ordersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete orders";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOrderProductsAttributes = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderProductsAttributes()
    .then((res) => {
      dispatch(actions.orderProductsAttributesFetched(res.data));
    })
    .catch((error) => {
      error.clientMessage = "Can't update orders status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
