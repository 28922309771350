import axios from "../../../utils/axios";

export const AUTH_URL = "/auth";
//export const LOGIN_URL = "api/auth/login";
export const LOGIN_URL = `${AUTH_URL}/login`;
export const REGISTER_URL = `${AUTH_URL}/register`;
export const REQUEST_PASSWORD_URL = `${AUTH_URL}/forgot-password`;

//export const ME_URL = "api/users/me";
export const ME_URL = `${AUTH_URL}/me`;

export async function login(username, password) {
  const result = await axios.post(LOGIN_URL, { username, password });
  localStorage.setItem("accessToken", result.headers["x-access-token"]);
  localStorage.setItem("refreshToken", result.headers["x-refresh-token"]);
  return result;
}

export function register(email, firstName, lastName, username, password) {
  return axios.post(REGISTER_URL, {
    email,
    firstName,
    lastName,
    username,
    password,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
