import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { ordersSlice } from "../app/modules/ECommerce/_redux/orders/ordersSlice";
import { ordersDetailedSlice } from "../app/modules/ECommerce/_redux/orders-detailed/ordersDetailedSlice";
import { configsSlice } from "../app/modules/ECommerce/_redux/configs/configsSlice";
import { usersSlice } from "../app/modules/ECommerce/_redux/users/usersSlice";
import { salesSummarySlice } from "../app/modules/ECommerce/_redux/reports/sales-summary/salesSummarySlice";
import { productionSummarySlice } from "../app/modules/ECommerce/_redux/reports/production-summary/productionSummarySlice";
import { orderDetailsSlice } from "../app/modules/ECommerce/_redux/reports/order-details/orderDetailsSlice"
import { inventoryHistorySlice } from "../app/modules/ECommerce/_redux/inventory-history/inventoryHistorySlice";

// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  orders: ordersSlice.reducer,
  ordersDetailed: ordersDetailedSlice.reducer,
  configs: configsSlice.reducer,
  users: usersSlice.reducer,
  salesSummary: salesSummarySlice.reducer,
  productionSummary: productionSummarySlice.reducer,
  orderDetails: orderDetailsSlice.reducer,
  inventory: inventoryHistorySlice.reducer
  // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
