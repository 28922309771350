import axios from "axios";
import store from "../../redux/store";
import { actions } from "../modules/Auth";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_PUBLIC_URL}/api/`
    : "http://localhost:8080/api/";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (successfulReq) => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    successfulReq.headers["x-access-token"] = `Bearer ${token}`;
    successfulReq.headers["x-refresh-token"] = `Bearer ${refreshToken}`;
    return successfulReq;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.headers["x-access-token"]) {
      localStorage.setItem("accessToken", response.headers["x-access-token"]);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(actions.logout());
    }
    return Promise.reject(error);
  }
);

export default api;
